import * as S from "./styles";

import useIsMobile from "../../Hooks/isMobile";

import { QuickAccesser } from "waitherepackages";
import { useNavigate } from "react-router-dom";
import { useQuickAccesser } from "../../Hooks/useQuickAccesser";

export const OrgQuickAccesser = ({ branding = {} }) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const {
    cart,
    orders,
    actions,
    session,
    tableNumber,
    hideAccesser,
    currentOrder,
    loading,
    selected,
    hasSession,
    setSelected,
  } = useQuickAccesser(navigate, branding);

  return (
    <S.Holder hide={hideAccesser}>
      {branding?.id && (
        <QuickAccesser
          cart={cart}
          orders={orders}
          currentOrder={currentOrder}
          loading={loading}
          actions={actions}
          isDefaultMoblie={isMobile}
          selected={selected}
          setSelected={setSelected}
          currentValues={branding?.quickaccesser}
          session={session}
          hasSession={hasSession}
          tableNumber={tableNumber}
        />
      )}
    </S.Holder>
  );
};
