/* eslint-disable react-hooks/exhaustive-deps */
import { FiSearch } from "react-icons/fi";
import { IconInput } from "../../Components/Inputs";
import { LoadDots } from "../../Components/Loader";
import { TopNav } from "../../Components/TopNav";
import { getImage } from "../../Helpers/getImage";
import { promos } from "./mockData";
import * as S from "./styles";
import { OrganizationCard } from "../../Components/OrganizationCard";
import useCategory from "../../Hooks/useCategory";

export const Category = () => {
  const { isLoading, currentCategory, orgsUnderCategory } = useCategory();

  const handleCardClick = (org) => {
    window.open(`/${org.slug}`, "_blank");
  };
  return (
    <>
      <TopNav />
      {isLoading.orgs && (
        <S.LoadHolder>
          <LoadDots />
        </S.LoadHolder>
      )}
      {!isLoading.orgs && (
        <>
          <S.Holder>
            <S.PromosHolder>
              {promos.map((promo, i) => (
                <S.ImageHolder key={i} image={getImage(8).src}>
                  <span>Get special discount</span>
                  <p>Up to 85%</p>
                  <S.ClaimVoucher>Claim Voucher</S.ClaimVoucher>
                </S.ImageHolder>
              ))}
            </S.PromosHolder>

            <S.SearchBarHolder>
              <IconInput
                placeHolder={"Search By Restaurant or meal"}
                leftIcon={<FiSearch />}
              />
            </S.SearchBarHolder>

            <S.CategoryName>
              Top Places that offer {currentCategory}
            </S.CategoryName>

            <S.OrgHolder>
              {orgsUnderCategory.map((org) => (
                <OrganizationCard
                  key={org.id}
                  org={org}
                  onClick={() => org.slug && handleCardClick(org)}
                />
              ))}
            </S.OrgHolder>
          </S.Holder>
        </>
      )}
    </>
  );
};
