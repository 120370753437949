import { api } from ".";
import { getUser, logout, setLogin } from "../Utils/localStorage";

export const userSigUp = async (param) =>
  await api.post(`/api/v1/users/`, {
    user: param,
  });

export const userLogIn = async (param) => {
  const response = await api.post(`/api/v1/users/login`, {
    user: param,
  });

  setLogin({
    ...response.data.user,
    token: response.headers.authorization,
  });

  return response;
};

export const userLogout = async (param) => {
  const user = getUser();
  logout();
  await api.delete("/api/v1/users/logout", {
    headers: {
      Authorization: user.token, //the token is a variable which holds the token
    },
  });
};
