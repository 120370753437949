import { useState } from "react";
import { getUser } from "../Utils/localStorage";
// import { updateUserAvatar } from "../Api/UserApi";

const useProfile = () => {
  const [avatar, setAvatar] = useState({
    current: `${getUser()?.avatar}`,
    new: null,
  });

  // const updateAvatar = useCallback(async () => {
  //   await updateUserAvatar(avatar.new);
  //   // setAvatar({ ...avatar, current: updatedAvatar });
  // }, [avatar]);

  return { avatar, setAvatar };
};

export default useProfile;
