import { createContext, useContext, useEffect, useState } from "react";
import { getBuilderState } from "../Api/BuilderApi";
import { image1 } from "./mockData";
import { userContext } from "./userContext";

export const builderContext = createContext();

const initialState = {
  currentFloor: "floor1",
  floors: {
    floor1: {
      floorName: "Ground Floor",
      bgImage: image1,
      items: [
        {
          id: 1,
          name: "table",
          type: "TenSitTable",
          position: {
            x: 549,
            y: 205,
          },
          status: "reserved",
          dimensions: {
            height: 50,
            width: 50,
          },
        },
      ],
    },
  },
};

const BuilderStore = ({ children }) => {
  const [builderState, setBuilderState] = useState(initialState);

  return (
    <builderContext.Provider value={[builderState, setBuilderState]}>
      {children}
    </builderContext.Provider>
  );
};

export default BuilderStore;
