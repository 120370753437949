/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useState } from "react";
import { isLogin } from "../../Utils/localStorage";
import { FilledButton } from "../Buttons";
import Modals from "../Modals";
import * as S from "./styles";
import * as Yup from "yup";
import { userLogIn } from "../../Api/UserApi";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .required("Password is required")
    .min(5, "Your password is too short."),
});

export const LoginModal = ({
  controllerState,
  setControllerState,
  canEdit,
}) => {
  const [pageState, setPageState] = useState({});

  const goToSignUp = () => {
    setControllerState({
      ...controllerState,
      modals: { ...controllerState.modals, login: false, signup: true },
    });
  };

  const formik = useFormik({
    initialValues: { email: controllerState?.user?.email, password: "" },
    validationSchema: LoginSchema,
    onSubmit: (values, formikBag) => {
      logIn(values);
    },
    enableReinitialize: true,
  });

  const logIn = async (values) => {
    setPageState({ ...pageState, isLoading: { logIn: true } });
    try {
      const { data } = await userLogIn(values);

      if (data.status === 200) {
        setControllerState({
          ...controllerState,
          modals: { login: false, signup: false },
        });
      }
    } catch (err) {
      formik.setErrors({ ...formik.errors, loginError: err?.response?.data });
    } finally {
      setPageState({ ...pageState, isLoading: { logIn: false } });
    }
  };

  return (
    <Modals
      size="sm"
      curved
      show={controllerState.modals?.login}
      backdrop="static"
      onHide={() => {
        if (isLogin() || controllerState.modals?.canCancel)
          setControllerState({ ...controllerState, modals: { login: false } });
      }}
    >
      <S.ModalHeader>Log in to your account</S.ModalHeader>

      <br />

      <S.InputHolder>
        <p>Email</p>
        <S.Input
          name="email"
          value={formik.values.email}
          placeholder="Email"
          onChange={canEdit ? formik.handleChange : undefined}
        />
        <S.Error>{formik.errors.email}</S.Error>
      </S.InputHolder>

      <S.InputHolder>
        <p>Password</p>
        <S.Input
          name="password"
          value={formik.values.password}
          placeholder="Password"
          type={"password"}
          onChange={formik.handleChange}
        />
        <S.Error>{formik.errors.password}</S.Error>
      </S.InputHolder>

      <br />

      <FilledButton
        isLoading={pageState?.isLoading?.logIn}
        variant={"login"}
        text={"Log in"}
        onClick={() => formik.handleSubmit()}
      />
      <S.Text>
        Need an account? <span onClick={goToSignUp}>Sign up</span>
      </S.Text>
    </Modals>
  );
};
