import styled from "styled-components";

export const Holder = styled.div`
  overflow-x: hidden;
  min-height: min-content;
  box-sizing: border-box;

  .nav_holder {
    position: absolute;
    max-width: 100%;
    z-index: 99;
  }

  .access_container {
    max-height: 0px;
    max-width: 70px !important;
    overflow: hidden;

    .quick_acess_holder {
      position: fixed;
    }
  }
`;

export const LoadHolder = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
