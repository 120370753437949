import { Resizable } from "re-resizable";
import styled from "styled-components";
import styles from "../../Assets/styles";

export const Resizer = styled(Resizable)`
  position: relative;
  border: ${(props) => props.focused && `1px solid black  `};
  border-radius: ${(props) => (props.isCircle ? "50%" : "10px")};
`;

export const NewDrag = styled.div`
  position: absolute !important;
  left: ${(props) => `${props.positions.x}px`};
  top: ${(props) => `${props.positions.y}px`};
  /* transition: 0.05s; */
  transform: ${(props) => props.rotation && `rotate(${props.rotation}deg)`};
`;

export const Rotater = styled.div`
  position: absolute !important;
  width: 15px;
  height: 15px;
  top: -30px;
  right: -10px;
  border-radius: 50%;
  background: ${styles.Black};
  cursor: pointer;
`;

export const IconHolder = styled.div`
  display: flex;
  align-self: center;
  box-sizing: border-box;
  width: 100%;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  max-width: 100%;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: ${(props) => props.expanded && `1px solid ${styles.DarkGrey}`};

  svg {
    width: 100%;
    height: 100%;
    color: red;
    fill: ${(props) =>
      props.status === "available"
        ? "green"
        : props.status === "occupied"
        ? "grey"
        : props.status === "reserved"
        ? "blue"
        : "black"};
  }
`;

export const DragHandle = styled.div`
  display: flex;
  align-self: center;
  box-sizing: border-box;
  width: 20px;
  min-width: 20px;
  max-height: 20px;
  min-height: 20px;
  max-width: 20px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: red;
  cursor: pointer;
  svg {
    color: red;
    fill: ${(props) =>
      props.status === "available"
        ? "green"
        : props.status === "occupied"
        ? "red"
        : props.status === "reserved"
        ? "grey"
        : "black"};
  }
`;
