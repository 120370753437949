import { api } from ".";
import paramsToUri from "../Helpers/paramsToUri";
import { getUser } from "../Utils/localStorage";

export const placeOrder = async (params) => {
  const user = getUser();

  return await api.post(`/api/v1/order/new`, params, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const getOrders = async (params) => {
  const user = getUser();

  return await api.get(
    `/api/v1/orders/organization/${params.slug}/user?${paramsToUri(params)}`,
    {
      headers: {
        Authorization: user?.token,
      },
    }
  );
};

export const getOrder = async ({ id, slug }) => {
  const user = getUser();

  return await api.get(`/api/v1/order/${id}?organization=${slug}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};
