/* eslint-disable react-hooks/exhaustive-deps */
// import * as S from "./styles";

import useIsMobile from "../../Hooks/isMobile";
import { useEffect } from "react";

import { TopNavBar } from "waitherepackages";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../Utils/localStorage";
import { useMemo } from "react";

export const OrgTopNav = ({ branding = {} }) => {
  const isMobile = useIsMobile();

  const navigate = useNavigate();

  const user = useMemo(() => ({ avatar: getUser()?.avatar }), []);

  const navbarData = useMemo(
    () => ({ ...branding?.navbar, user }),
    [branding, user]
  );
  const handleNavigate = (slug) => {
    navigate(slug);
  };

  const actions = {
    navigate: (slug) => handleNavigate(slug),
  };

  useEffect(() => {
    document.title = branding?.navbar?.organization?.name || "Waithere";
  }, [branding]);

  return (
    <>
      {branding?.id && (
        <TopNavBar
          actions={actions}
          isDefaultMoblie={isMobile}
          currentValues={navbarData}
        />
      )}
    </>
  );
};
