import { OrgTopNav } from "../OrgTopNav";
import React, { cloneElement } from "react";
import useBranding from "../../Hooks/useBranding";
import { OrgFooter } from "../OrgFooter";
import * as S from "./styles";
import { LoadDots } from "../Loader";
import { OrgQuickAccesser } from "../OrgQuickAccess";
export const OrganizationPage = ({ children }) => {
  const { branding, loading } = useBranding({
    fetchOnLoad: true,
  });

  return (
    <>
      {loading.branding && (
        <S.LoadHolder>
          <LoadDots />
        </S.LoadHolder>
      )}

      {!loading.branding && (
        <S.Holder>
          <OrgTopNav branding={branding} />
          {cloneElement(children, { branding: branding })}
          <OrgFooter branding={branding} />
          <OrgQuickAccesser branding={branding} />
        </S.Holder>
      )}
    </>
  );
};
