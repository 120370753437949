import styled from "styled-components";
import styles from "../../Assets/styles";

export const Organizations = styled.div`
  height: 120px;
  min-height: 120px;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  border-radius: 12px;
  padding: 0px 20px;
  border: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f8f8f8;
  overflow: hidden;
  transition: 0.3s;
  cursor: pointer;
  :hover {
    transition: 0.3s;
    box-shadow: 0px 5px 20px rgba(79, 79, 79, 0.25);
  }

  @media (max-width: ${1000}px) {
    box-shadow: 0px 5px 20px rgba(79, 79, 79, 0.25);
  }

  img {
    width: 90px;
    min-width: 90px;
    height: 90px;
    min-height: 90px;
    border-radius: 10px;
    object-fit: cover;
    background-color: #f2f2f2;
  }
  .details-holder {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    padding: 20px 10px;
    gap: 15px;
    width: fill-available;
    max-width: 90%;
  }
  .org-name {
    margin: 0;
    max-width: 90%;
    text-overflow: ellipsis;
    font-size: 17px;
    overflow: hidden;
    white-space: nowrap;
  }
  .org-location {
    color: ${styles.DarkGrey};
    max-width: 80%;
    text-overflow: ellipsis;
    font-size: 17px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 17px;
  }

  @media (max-width: ${1000}px) {
    width: 100%;
    max-width: 350px;
  }
`;
