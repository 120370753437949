import { api } from ".";
import { getUser } from "../Utils/localStorage";

export const startSession = async (params) => {
  const user = getUser();

  return await api.post(`/api/v1/sessions/create_session`, params, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const updateSession = async (params) => {
  const user = getUser();

  return await api.patch(`/api/v1/sessions/update_session`, params, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const getSession = async (token) => {
  const user = getUser();

  return await api.get(`/api/v1/sessions/${token}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const hasActiveOrPendingSession = async () => {
  const user = getUser();

  return await api.get(`/api/v1/session`, {
    headers: {
      Authorization: user?.token,
    },
  });
};
