/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from "react";
import { controllerContext } from "../../Context/controllerCtx";
import { LoginModal } from "./LoginModal";
import { SignUpModal } from "./SignupModal";

export const UniversalModals = () => {
  const [controllerState, setControllerState] = useContext(controllerContext);

  return (
    <>
      <LoginModal
        canEdit={true}
        controllerState={controllerState}
        setControllerState={setControllerState}
      />
      <SignUpModal
        canEdit={true}
        controllerState={controllerState}
        setControllerState={setControllerState}
      />
    </>
  );
};
