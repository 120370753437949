// /api/v1/menu_category/

import { api } from ".";
import { getUser } from "../Utils/localStorage";

// public

export const getMenuCategories = async (org) => {
  const user = getUser();

  return await api.get(`/api/v1/public/menu/categories/${org}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const getMenuItems = async (id) => {
  const user = getUser();

  return await api.get(`/api/v1/public/menu/items/${id}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const getMenuItemsPreview = async (slug, params) => {
  const user = getUser();

  return await api.post(`/api/v1/public/menu/items-preview/${slug}`, params, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const addToCart = async (params) => {
  const user = getUser();

  return await api.post(`/api/v1/cart/add_item`, params, {
    headers: {
      Authorization: user?.token,
    },
  });
};

export const searchMenuItems = async (query) => {
  const user = getUser();

  return await api.post(`/api/v1/public/menu/items/search`, query, {
    headers: {
      Authorization: user?.token,
    },
  });
};
