// import { userLogout } from "../../Api/UserApi";
import { TopNav } from "../../Components/TopNav";
import { ClientHome } from "./ClientHome";
import * as S from "./styles";

export const Home = () => {
  return (
    <>
      <TopNav />
      <S.Holder>
        <ClientHome />
      </S.Holder>
    </>
  );
};
