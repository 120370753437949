import { waithere } from "../../Assets/images/images";
import { IconInput } from "../Inputs";
import * as S from "./styles";
import { FiSearch } from "react-icons/fi";
import { TbPaperBag } from "react-icons/tb";
import { Collapse } from "react-bootstrap";

import { IoIosMenu } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { useRef } from "react";
import { useState } from "react";
import useIsMobile from "../../Hooks/isMobile";
import { AcountRoutes, MenuRoutes } from "./NavRoutes";
import { useNavigate } from "react-router-dom";
import useProfile from "../../Hooks/useProfile";

export const TopNav = () => {
  const holderRef = useRef();
  const navigate = useNavigate();
  const { avatar } = useProfile();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <S.Holder ref={holderRef}>
      {!useIsMobile() ? (
        <>
          <S.Sections>
            <img src={waithere} alt="logo" onClick={() => navigate("/")} />
            <MenuRoutes />
          </S.Sections>
          <S.Sections>
            <S.SearchBarHolder>
              <IconInput
                placeHolder={"Search By Restaurant or meal"}
                leftIcon={<FiSearch />}
              />
            </S.SearchBarHolder>
            <S.CartButton>
              <TbPaperBag />
            </S.CartButton>
            <S.UserImage
              onMouseEnter={() => setIsExpanded(true)}
              onMouseLeave={() => setIsExpanded(false)}
              image={`${
                avatar.new ? URL.createObjectURL(avatar.new) : avatar.current
              }`}
            >
              <Collapse in={isExpanded}>
                <S.WebCollapser>
                  <S.UserSection>
                    <AcountRoutes avatar={avatar} />
                  </S.UserSection>
                </S.WebCollapser>
              </Collapse>
            </S.UserImage>
          </S.Sections>
        </>
      ) : (
        <>
          <S.Sections>
            <S.CartButton>
              <div onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? <IoClose /> : <IoIosMenu />}
              </div>

              <Collapse in={isExpanded}>
                <S.Collapser>
                  <S.SearchBarHolder>
                    <IconInput
                      placeHolder={"Search By Restaurant or meal"}
                      leftIcon={<FiSearch />}
                    />
                  </S.SearchBarHolder>

                  <MenuRoutes />
                  <hr />
                  <S.UserSection>
                    <AcountRoutes avatar={avatar} />
                  </S.UserSection>
                </S.Collapser>
              </Collapse>
            </S.CartButton>
          </S.Sections>

          <S.Sections>
            <img src={waithere} alt="logo" onClick={() => navigate("/")} />
          </S.Sections>

          <S.Sections>
            <S.CartButton>
              <TbPaperBag />
            </S.CartButton>
          </S.Sections>
        </>
      )}
    </S.Holder>
  );
};

// continue tommorow
