import axios from "axios";

const stagingApi = "https://staging.api.waithere.com.br";
const prodApi = "https://api.example.com/api";

const currentUrl = window.location.href.toLowerCase();
const api_url = () => {
  if (currentUrl.includes("localhost") || currentUrl.includes("onrender")) {
    return process.env.REACT_APP_API_URL;
  } else if (currentUrl.includes("staging")) {
    return stagingApi;
  } else {
    return prodApi;
  }
};

export const api = axios.create({
  baseURL: api_url(),
  headers: {
    "Content-Type": "application/json",
  },
});
