import styled from "styled-components";
import styles from "../../Assets/styles";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${styles.MidGrey};
`;

export const MidContainer = styled.div`
  height: 80vh;
  max-height: 600px;
  min-width: 1000px;
  max-width: 1000px;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  background-color: ${styles.NeutralsWhite};
  border-bottom-right-radius: 50px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${1000}px) {
    min-width: 80%;
    max-height: 100vh;
    overflow: hidden;
    border-radius: 0;
    height: 100%;
    width: 100%;
  }
`;

export const leftSection = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  padding: 0px 30px;

  @media (max-width: ${1000}px) {
    width: 100%;
    height: 100%;
    justify-self: center;
    align-items: center;
    div {
      width: 100%;
    }

    p {
      align-self: start;
    }
  }
`;

export const Company = styled.p`
  font-weight: 700;

  @media (max-width: ${1000}px) {
    margin-top: 100px;
    margin-bottom: 100px;
  }
`;

export const BoldText = styled.p`
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  padding: 0;
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 13px;
  margin: 0;
  padding: 0;
`;

export const LoginText = styled.p`
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  margin-left: 30px;
  margin-top: 10px;
  padding: 0;
  span {
    text-decoration: underline;
    text-decoration-color: ${styles.Black};
    cursor: pointer;
    font-weight: 700;
  }

  @media (max-width: ${1000}px) {
    align-self: center !important;
  }
`;

export const rightSection = styled.div`
  width: 650px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  overflow: hidden;
  @media (max-width: ${1000}px) {
    display: none;
  }
`;

export const InputHolder = styled.div`
  gap: 2rem;
  width: 250px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${1000}px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;
