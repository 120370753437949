import { useContext, useState } from "react";
import { builderContext } from "../../Context/builderCtx";
import { TemplateUploader } from "../BgTemplateUploader";
import { FilledButton, UnFilledButton } from "../Buttons";
import Modals from "../Modals";
import * as S from "../Modals/styles";

export const FloorModal = ({ showModal, setShowModal }) => {
  const [builderState, setBuilderState] = useContext(builderContext);

  const [newFloor, setNewFloor] = useState({});

  const addNewFloor = () => {
    const floorId = `floor${Math.random()}`;
    setBuilderState({
      ...builderState,
      currentFloor: floorId,
      floors: {
        ...builderState.floors,
        [floorId]: newFloor,
      },
    });

    setShowModal({ ...showModal, floors: false });
  };

  return (
    <>
      <Modals
        size="md"
        curved
        show={showModal.floors}
        onHide={() => setShowModal({ ...showModal, floors: false })}
      >
        <S.ModalHeader>Add New Floor</S.ModalHeader>
        <S.SubModalHeader>
          Upload and attach your new Floor Template
        </S.SubModalHeader>

        <S.Input
          placeholder="Name of Floor"
          onChange={(e) =>
            setNewFloor({ ...newFloor, floorName: e.target.value })
          }
        />
        <S.ContentHolder>
          <TemplateUploader
            onAdd={(baseUrl) => setNewFloor({ ...newFloor, bgImage: baseUrl })}
          />
        </S.ContentHolder>

        <S.ButtonsHolder>
          <UnFilledButton
            text={"Cancel"}
            onClick={() => setShowModal({ ...showModal, floors: false })}
          />

          <FilledButton
            text={"Add Floor"}
            onClick={() => newFloor.bgImage && addNewFloor()}
          />
        </S.ButtonsHolder>
      </Modals>
    </>
  );
};
