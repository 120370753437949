// import * as S from "./styles";

import useIsMobile from "../../Hooks/isMobile";
import { Footers } from "waitherepackages";
import { useNavigate } from "react-router-dom";

export const OrgFooter = ({ branding = {} }) => {
  const isMobile = useIsMobile();

  const navigate = useNavigate();

  const handleNavigate = (slug) => {
    navigate(slug);
  };

  const actions = {
    navigate: (slug) => handleNavigate(slug),
  };

  return (
    <>
      {branding?.id && (
        <Footers
          actions={actions}
          isDefaultMoblie={isMobile}
          currentValues={branding?.footer}
        />
      )}
    </>
  );
};
