import styled from "styled-components";
import styles from "../../Assets/styles";

export const Container = styled.div `
  width: 100%;
  height: 100vh;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: ${styles.MidGrey};
`;

export const BGTemplateHolder = styled.div `
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${styles.BgBlue};
  /* padding-left: 80px; */
`;

export const BGTemplate = styled.div `
  position: relative;
  transition: 0.3s;
  /* width: 57vw;
  height: 65vh; */
  width: 850px;
  height: 500px;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: ${styles.NeutralsWhite};
  background-image: ${(props) => `url(${props.image})`};
  background-position: center;
  background-size: cover;
  background-repeat: none;
  cursor: ${({ controls }) => (controls.isAdding ? "crosshair" : "pointer")};
`;

export const FloorName = styled.p`
  transition: 0.5s;
  font-size: 20px;
  font-weight: 500;
  color: ${styles.NeutralsWhite};
  margin-bottom: 10px;
`;

// https://dribbble.com/shots/8297994-ikas-Dashboard-Image-Upload-Animation/attachments/641415?mode=media