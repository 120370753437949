import { useContext } from "react";
import { builderContext } from "../../../Context/builderCtx";
import * as S from "../styles";

export const Templates = () => {
  const [builderState, setBuilderState] = useContext(builderContext);
  let currentFloor = builderState.currentFloor;
  let floors = builderState.floors;

  const alignPosition = () => {
    floors[currentFloor] = {
      ...floors[currentFloor],
      items: floors[currentFloor].items.map((item) => {
        return {
          ...item,
          position: {
            x: item.position.x,
            y: Math.ceil(item.position.y / 50) * 50,
          },
        };
      }),
    };
    setBuilderState({
      ...builderState,
      floors: floors,
    });
  };

  return (
    <>
      <S.Header>Templates</S.Header>

      <S.SubText>Current Template</S.SubText>

      {builderState.floors[currentFloor].bgImage ? (
        <S.CurrentTemplateHolder
          onClick={() => alignPosition()}
          image={builderState.floors[currentFloor].bgImage}
        />
      ) : (
        "Upload here"
      )}
    </>
  );
};

// penha
// penha
// penha
// penha
