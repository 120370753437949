import styled from "styled-components";
import styles from "../../Assets/styles";

export const ModalHeader = styled.p`
  font-size: 19px;
  font-weight: 700;
  color: ${styles.OffBlack};
  /* text-align: center; */
  margin-top: -35px;
`;

export const Input = styled.input`
  width: 99%;
  height: 40px;
  border-radius: 10px;
  border: ${`1px solid ${styles.IconGrey}`};
  padding-left: 10px;
  /* margin-bottom: 15px; */

  ::placeholder {
    color: ${styles.FocusedGrey};
  }
  :focus {
    outline: none;
  }
`;

export const ButtonsHolder = styled.div`
  margin-top: 20px;
  margin-left: -30px;
  margin-right: -30px;
  border-top: ${`1px solid  ${styles.IconGrey}`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
`;

export const InputHolder = styled.div`
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 0;
    padding: 0;
    font-weight: 600;
    margin-bottom: 5px;
  }
`;

export const Text = styled.p`
  margin-top: 10px;
  font-size: 15px;
  text-align: center;
  color: ${styles.FocusedGrey};
  span {
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
  }
`;

export const Error = styled.div`
  font-weight: 500;
  color: ${styles.Error};
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
