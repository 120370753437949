/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCategories } from "../../Api/CategoryApi";
import { LoadDots } from "../../Components/Loader";
import { getImage } from "../../Helpers/getImage";
import * as S from "./styles";

export const ClientHome = () => {
  const navigate = useNavigate();

  const [clientHomeState, setClientHomeState] = useState({ categories: [] });
  const [pageState, setPageState] = useState({});

  const apiCalls = async () => {
    setPageState({ ...pageState, isLoading: true });
    const { data } = await getCategories();
    setClientHomeState({ ...clientHomeState, categories: data.categories });
    setPageState({ ...pageState, isLoading: false });
  };

  useEffect(() => {
    apiCalls();
  }, []);

  return (
    <>
      {pageState.isLoading && (
        <S.LoadHolder>
          <LoadDots />
        </S.LoadHolder>
      )}
      {!pageState.isLoading && (
        <S.HomeHolder>
          <S.CategoriesHolder>
            {clientHomeState.categories.map((val, i) => (
              <S.Category
                key={i}
                onClick={() => navigate(`/category/${val.category}`)}
              >
                <S.CategoryImage
                  image={getImage(parseInt(val.image_src) || 1).src}
                ></S.CategoryImage>
                <p>{val.category}</p>
              </S.Category>
            ))}
          </S.CategoriesHolder>
        </S.HomeHolder>
      )}
    </>
  );
};
