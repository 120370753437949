/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState } from "react";
import { builderContext } from "../../Context/builderCtx";
import { controllerContext } from "../../Context/controllerCtx";
import { allMovebles } from "./allMoveables";
import * as S from "./styles";

export const MoveableItems = () => {
  const [builderState, setBuilderState] = useContext(builderContext);
  const [controllerState, setControllerState] = useContext(controllerContext);

  const [offset, setOffset] = useState([]);

  let currentFloor = builderState.currentFloor;
  let floors = builderState.floors;

  const getDraggableDimensions = (element) => {
    if (element) {
      let boxBoundingRect = element.getBoundingClientRect();
      let boxCenter = {
        x: boxBoundingRect?.left + boxBoundingRect?.width / 2,
        y: boxBoundingRect?.top + boxBoundingRect?.height / 2,
      };
      boxBoundingRect["boxCenter"] = boxCenter;
      return boxBoundingRect;
    }
  };

  const setFocused = (item) => {
    setControllerState({ ...controllerState, focused: item, isAdding: false });
  };

  const setItemPosition = (e, currentItem) => {
    if (controllerState.isDrag) {
      floors[currentFloor] = {
        ...floors[currentFloor],
        items: floors[currentFloor].items.map((item) => {
          if (item.name !== currentItem.name) {
            return item;
          } else {
            return {
              ...item,
              position: { x: e.clientX + offset[0], y: e.clientY + offset[1] },
            };
          }
        }),
      };
      setBuilderState({
        ...builderState,
        floors: floors,
      });
    }
  };

  const setItemDimensions = (currentItem, d) => {
    floors[currentFloor] = {
      ...floors[currentFloor],
      items: floors[currentFloor].items.map((item) => {
        if (item.name !== currentItem.name) {
          return item;
        } else {
          return {
            ...item,
            dimensions: {
              height: item.dimensions.height + d.height,
              width: item.dimensions.width + d.width,
            },
          };
        }
      }),
    };
    setBuilderState({
      ...builderState,
      floors: floors,
    });
  };

  const startDrag = (post, e) => {
    setControllerState({ ...controllerState, isDrag: true });
    setOffset([post.x - e.clientX, post.y - e.clientY, true]);
  };

  const stopDrag = () => {
    setControllerState({ ...controllerState, isDrag: false });
  };

  const handleRotation = (e, currentItem) => {
    const boxDimensions = getDraggableDimensions(e.target.parentElement);

    const angle =
      Math.atan2(
        e.pageX - boxDimensions.boxCenter.x,
        -(e.pageY - boxDimensions.boxCenter.y)
      ) *
      (180 / Math.PI);

    floors[currentFloor] = {
      ...floors[currentFloor],
      items: floors[currentFloor].items.map((item) => {
        if (item.name !== currentItem.name) {
          return item;
        } else {
          return {
            ...item,
            rotation: angle,
          };
        }
      }),
    };

    setBuilderState({
      ...builderState,
      floors: floors,
    });
  };

  return (
    <>
      {builderState.floors[currentFloor].items?.map((item) => {
        let isFocused = item.name === controllerState?.focused?.name;
        let enabled = {
          top: isFocused,
          right: isFocused,
          bottom: isFocused,
          left: isFocused,
          topRight: isFocused,
          bottomRight: isFocused,
          bottomLeft: isFocused,
          topLeft: isFocused,
        };
        var img = document.createElement("img");

        return (
          <S.NewDrag
            draggable={controllerState.isDrag}
            key={item.id}
            positions={{
              x: item.position.x,
              y: item.position.y,
            }}
            rotation={item.rotation}
            onDragEnd={(e) => {
              if (controllerState.isDrag) {
                setItemPosition(e, item);
              }
            }}
            onDragStart={(e) => {
              e.dataTransfer.setDragImage(img, 0, 0);
            }}
            onDrag={(e) => {
              if (controllerState.isDrag) {
                setItemPosition(e, item);
              }
            }}
            onMouseDown={(e) => {
              startDrag({ x: item.position.x, y: item.position.y }, e);
            }}
            onMouseUp={() => stopDrag()}
            onClick={() => {
              setFocused(item);
            }}
          >
            {isFocused && (
              <S.Rotater
                draggable
                onDrag={(e) => {
                  e.currentTarget.style.background = "transparent";
                  handleRotation(e, item);
                }}
                onDragStart={(e) => {
                  setControllerState({
                    ...controllerState,
                    isRotate: true,
                    isDrag: false,
                  });
                }}
                onDragEnd={(e) => {
                  e.currentTarget.style.background = "black";
                  handleRotation(e, item);
                  setControllerState({
                    ...controllerState,
                    isRotate: false,
                    isDrag: false,
                  });
                }}
              />
            )}
            <S.Resizer
              defaultSize={{
                width: item.dimensions.width,
                height: item.dimensions.height,
              }}
              onResizeStop={(e, direction, ref, d) => {
                setItemDimensions(item, d);
                setControllerState({ ...controllerState, isResize: false });
              }}
              onResize={() => stopDrag()}
              lockAspectRatio
              focused={isFocused}
              enable={enabled}
              isCircle={item.dimensions.width % item.dimensions.height === 0}
            >
              <S.IconHolder status={item.status}>
                {allMovebles.map((moveable, i) => {
                  if (moveable.name === item.type) {
                    return moveable.icon;
                  }
                })}
              </S.IconHolder>
            </S.Resizer>
          </S.NewDrag>
        );
      })}
    </>
  );
};
