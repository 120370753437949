import { userLogout } from "../Api/UserApi";
import Cookies from "universal-cookie";

export const isLogin = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user?.token) return true;
  return false;
};

export const setLogin = (user) => {
  localStorage.clear();
  localStorage.setItem("user", JSON.stringify(user));
  setLoginTime();
};

export const logout = () => {
  localStorage.clear();
  const cookies = new Cookies();
  cookies.remove("session_token", { path: "/" });
};

export const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const setLoginTime = () => {
  var now = new Date().getTime();
  localStorage.setItem("setupTime", now);
};

export const verifyLogIn = () => {
  const day = 24;
  const amountOfDays = 5;
  var time = day * amountOfDays;
  // to clear the localStorage after 1 day
  // (if someone want to clear after 1 simply change amountOfDays=1)

  var now = new Date().getTime();
  var setupTime = localStorage.getItem("setupTime");

  if (setupTime == null) {
    localStorage.setItem("setupTime", now);
  } else {
    if (now - setupTime > time * 60 * 60 * 1000) {
      userLogout();
      localStorage.clear();
      window.location.reload();
    }
  }
};
