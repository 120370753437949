import { useContext } from "react";
// import { builderContext } from "../../../Context/builderCtx";
import { controllerContext } from "../../../Context/controllerCtx";
import { allMovebles } from "../../MoveableItems/allMoveables";
import * as S from "../styles";

export const Items = () => {
  // const [builderState] = useContext(builderContext);
  const [controllerState, setControllerState] = useContext(controllerContext);

  return (
    <>
      <S.Header>Items</S.Header>

      {/* <S.SubText>Items In Use</S.SubText> */}

      <S.ItemsHolder>
        {allMovebles.map((moveable, i) => {
          return (
            <S.IconHolder
              key={i}
              onClick={() =>
                setControllerState({
                  ...controllerState,
                  isAdding:
                    controllerState.isAdding.name === moveable.name
                      ? false
                      : moveable,
                })
              }
            >
              {moveable.icon}
              <p>{moveable.displayName}</p>
            </S.IconHolder>
          );
        })}
      </S.ItemsHolder>
    </>
  );
};
