import styled from "styled-components";
import styles from "../../Assets/styles";

export const Container = styled.div`
  position: absolute;
  transition: 0.3s;
  left: 15px;
  align-self: center;
  display: flex;
  border-radius: 10px;
  width: ${(props) => (props.expanded ? "350px" : "80px")};
  background-color: ${styles.DarkBlue};
  overflow: hidden;
  z-index: 99;
`;
export const Holder = styled.div`
  box-sizing: border-box;
  width: 80px;
  min-width: 80px;
  height: 90vh;
  max-width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${styles.DarkBlue};
  padding-top: 20px;
  gap: 20px;
  border-right: ${(props) => props.expanded && `1px solid ${styles.DarkGrey}`};
`;

export const ExpandedHolder = styled.div`
  transition: 0.3s;
  box-sizing: border-box;
  width: 269px;
  height: 90vh;
  max-width: 269px;
  display: flex;
  flex-direction: column;
  background-color: ${styles.DarkBlue};
  padding-top: 20px;
  padding-left: 20px;
  gap: 20px;
`;

export const Header = styled.p`
  color: ${styles.NeutralsWhite};
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.05em;
`;

export const SubText = styled.p`
  font-size: 15px;
  color: ${styles.DarkGrey};
  font-weight: 500;
`;

export const ItemsHolder = styled.p`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: space-between;
  padding-right: 20px;
`;

export const IconHolder = styled.div`
  padding: 5px;
  display: flex;
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 10px;
  p {
    color: ${styles.NeutralsWhite};
    text-align: center;
    font-size: 10px;
    max-width: fit-content;
    margin-top: 5px;
    margin: 0;
    padding: 0;
  }
  svg {
    fill: ${(props) => styles.IconGrey};
    max-width: 100%;
  }
  :hover {
    background-color: ${styles.FocusedGrey};
  }
`;

export const CurrentTemplateHolder = styled.div`
  display: flex;
  /* align-self: center; */
  border-radius: 5px;
  width: 230px;
  height: 120px;
  background-color: red;
  margin-top: -10px;

  background-image: ${(props) => `url(${props.image})`};
  background-position: center;
  background-size: cover;
  background-repeat: none;
`;

export const NavOption = styled.div`
  transition: 0.3s;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.focused && `${styles.FocusedGrey}`};
  cursor: pointer;
  :hover {
    background-color: ${styles.FocusedGrey};
  }

  svg {
    color: ${styles.IconGrey};
  }

  span {
    font-size: 8px;
    color: ${styles.NeutralsWhite};
  }
`;
