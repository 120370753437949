import { useContext, useState } from "react";
import { builderContext } from "../../Context/builderCtx";
import { MdAdd } from "react-icons/md";
import * as S from "./styles";
import { BsImages } from "react-icons/bs";
import { FloorModal } from "./modals";

export const Floors = () => {
  const [builderState, setBuilderState] = useContext(builderContext);
  const [showModal, setShowModal] = useState({});
  let currentFloor = builderState.currentFloor;
  let floors = builderState.floors;

  const setCurrentFloor = (floor) => {
    setBuilderState({ ...builderState, currentFloor: floor });
  };

  return (
    <>
      <S.Holder>
        {Object.keys(floors).map((key, index) => (
          <S.FloorHolder key={index}>
            <S.Floor
              focused={currentFloor === key}
              onClick={() => setCurrentFloor(key)}
              image={floors[key].bgImage}
            >
              {!floors[key].bgImage && <BsImages />}
            </S.Floor>
            <p>{floors[key].floorName}</p>
          </S.FloorHolder>
        ))}

        <S.FloorHolder key={"add"}>
          <S.Floor onClick={() => setShowModal({ ...showModal, floors: true })}>
            <MdAdd />
          </S.Floor>
          <p>{"Add Floor"}</p>
        </S.FloorHolder>
      </S.Holder>

      <FloorModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};
