// import useOrganization from "../../../Hooks/useOrganization";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";
import { Menus } from "waitherepackages";
import { useMenu } from "../../../Hooks/useMenu";
import useIsMobile from "../../../Hooks/isMobile";

export const OrganizationMenu = ({ branding }) => {
  const navigate = useNavigate();
  const menuProps = useMenu(navigate);
  const isMobile = useIsMobile();

  const {
    handleShowSearch,
    handleAddToCart,
    hideSearchMenuItem,
    handleCategoryChange,
    handleMenuItemChange,
    handleMenuItemClose,
    handleSearchMenuItems,
  } = menuProps;

  const handleNavigate = (slug) => {
    navigate(slug);
  };

  const actions = {
    navigate: handleNavigate,
    selectCategory: handleCategoryChange,
    handleMenuItemClick: handleMenuItemChange,
    hideMenuItem: handleMenuItemClose,
    handleOrder: handleAddToCart,
    hideSearchMenuItem: hideSearchMenuItem,
    handleShowSearch: handleShowSearch,
    handleSearchMenuItems: handleSearchMenuItems,
  };

  return (
    <S.Holder>
      <Menus
        actions={actions}
        isDefaultMoblie={isMobile}
        currentValues={branding.menu}
        {...menuProps}
      />
    </S.Holder>
  );
};
