import {
  restaurant1,
  restaurant2,
  restaurant3,
  restaurant4,
} from "../../Assets/images/images";
import { FilledButton, UnFilledButton } from "../../Components/Buttons";
import { Input } from "../../Components/Inputs";
import * as S from "./styles";
import { FcGoogle } from "react-icons/fc";
import { Slider } from "../../Components/ImageSlider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { userLogIn } from "../../Api/UserApi";
import { userContext } from "../../Context/userContext";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../Utils/localStorage";
// import { controllerContext } from "../../Context/controllerCtx";
import { ErrorText } from "../../Components/Error";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .required("Password is required")
    .min(5, "Your password is too short."),
});

export const Login = () => {
  const [userState, setUserState] = useContext(userContext);
  // const [controllerState, setControllerState] = useContext(controllerContext);

  const [pageState, setPageState] = useState({});

  const navigate = useNavigate();

  const sliderData = [
    { image: restaurant1, text: "Testing " },
    { image: restaurant2, text: "Testing " },
    { image: restaurant3 },
    { image: restaurant4 },
  ];

  const logIn = async (values) => {
    setPageState({ ...pageState, isLoading: { logIn: true } });
    try {
      const { data } = await userLogIn(values);
      if (data.status === 200) {
        setUserState({ ...userState, user: data.user });
        navigate("/home");
      }
    } catch (err) {
      formik.setErrors({ ...formik.errors, loginError: err.response.data });
    }
    setPageState({ ...pageState, isLoading: { logIn: false } });
  };

  const formik = useFormik({
    initialValues: { email: getUser()?.email, password: "" },
    validationSchema: LoginSchema,
    onSubmit: (values, formikBag) => {
      logIn(values);
    },
    enableReinitialize: true,
  });

  return (
    <S.Container>
      <S.MidContainer>
        <S.leftSection>
          <S.Company>WAIThERE</S.Company>

          <S.BoldText>Log into your account</S.BoldText>
          <S.Text>Login into your account to continue.</S.Text>
          <br />
          <S.InputHolder>
            <Input
              value={formik.values.email}
              placeHolder={"Email"}
              errors={formik.errors.email}
              onChange={(value) => formik.setFieldValue("email", value)}
            />
            <Input
              type={"password"}
              placeHolder={"Password"}
              errors={formik.errors.password}
              onChange={(value) => formik.setFieldValue("password", value)}
            />
            {formik.errors.loginError && (
              <ErrorText error={formik.errors.loginError} />
            )}
          </S.InputHolder>
          <br />
          <FilledButton
            isLoading={pageState?.isLoading?.logIn}
            text={"Login"}
            variant={"signup"}
            onClick={() => formik.handleSubmit()}
          />
          <br />
          <UnFilledButton
            text={"Log in with Google "}
            leftIcon={<FcGoogle />}
            variant={"signup"}
          />
          <br />
          <S.LoginText>
            Don't have an account?{" "}
            <span onClick={() => navigate("/sign-up")}>Sign Up</span>
          </S.LoginText>
        </S.leftSection>

        <S.rightSection image={restaurant2}>
          <Slider data={sliderData} />
        </S.rightSection>
      </S.MidContainer>
    </S.Container>
  );
};
