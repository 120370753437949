import * as S from "./styles";
import { CiFileOn } from "react-icons/ci";
import { FiUploadCloud } from "react-icons/fi";
import { BsImages } from "react-icons/bs";
import { useState } from "react";
import { IoClose } from "react-icons/io5";

export const TemplateUploader = ({ onAdd }) => {
  const [progress, setProgress] = useState(0);
  const [image, setImage] = useState({});
  const toDataURL = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    const imageUrl = URL.createObjectURL(newFile);

    let currentProgress = 1;
    const id = setInterval(frame, 10);

    function frame() {
      if (currentProgress >= 100) {
        toDataURL(imageUrl, (dataUrl) => {
          onAdd(dataUrl);
          setImage({ image: dataUrl, imageData: newFile });
          clearInterval(id);
        });
      } else {
        currentProgress++;
        setProgress(currentProgress);
      }
    }
  };

  return (
    <>
      <S.Holder>
        <S.Uploader>
          <input type="file" value="" onChange={onFileDrop} />
          <S.UploadIcon>
            <CiFileOn />
            <div>
              <FiUploadCloud />
            </div>
          </S.UploadIcon>

          <S.Text>
            <span>Click to upload</span> or drag and drop
          </S.Text>
          <S.SubText>Supports JPG, JPEG, PNG</S.SubText>
        </S.Uploader>
      </S.Holder>

      {progress > 1 && (
        <S.ProgresHolder>
          <S.CloseIcon
            onClick={() => {
              setImage({});
              setProgress(0);
            }}
          >
            <IoClose />
          </S.CloseIcon>
          <S.TopFlex>
            <S.FloorPreview image={progress === 100 && image.image}>
              {progress !== 100 && <BsImages />}
            </S.FloorPreview>

            <div>
              <S.FileName>
                {image?.imageData?.name && image?.imageData?.name}
              </S.FileName>
              <S.FileSize>
                {Math.round(image?.imageData?.size / 1000)} MB
              </S.FileSize>
            </div>
          </S.TopFlex>

          {progress > 0 && (
            <S.LoaderHolder>
              <S.Loader progress={progress}>
                <div></div>
              </S.Loader>
              <p>{progress}%</p>
            </S.LoaderHolder>
          )}
        </S.ProgresHolder>
      )}
    </>
  );
};
