/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from "react";
import { OrderContext } from "../Context/orderContext";

const useOrder = () => {
  const context = useContext(OrderContext);

  return context;
};

export default useOrder;
