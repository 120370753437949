import styled from "styled-components";
import styles from "../../Assets/styles";

export const Holder = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  border-bottom: 1px solid ${styles.MidGrey};

  input {
    width: 100%;
    border: 0;
    color: ${styles.Black};
    background: transparent !important;

    :focus {
      border: 0;
      outline: 0;
    }

    ::placeholder {
      color: ${styles.Black};
    }
    :-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }
`;

export const Error = styled.div`
  font-weight: 500;
  color: ${styles.Error};
`;

export const IconInputHolder = styled(Holder)`
  border-radius: 4px;
  border: 0;
  min-height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 5px;
  gap: 10px;
  svg {
    color: ${styles.OffBlack};
    height: 25px;
    width: 25px;
  }
  input {
    ::placeholder {
      color: ${styles.MidGrey} !important;
    }
  }
`;
