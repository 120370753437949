import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Builder } from "./Features/Builder";
import { Category } from "./Features/Categories";
import { Home } from "./Features/Home";
import { Login } from "./Users/Login";
import { SignUp } from "./Users/SignUp/signUp";
import { OrgHome } from "./Features/OrganizationPage/Home";
import { OrganizationMenu } from "./Features/OrganizationPage/Menu";
import { OrganizationPage } from "./Components/OrganizationPage";

export const Pages = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/builder" element={<Builder />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/log-in" element={<Login />} />
        <Route path="/category/:category" element={<Category />} />

        <Route
          path="/:organization"
          element={
            <OrganizationPage>
              <OrgHome />
            </OrganizationPage>
          }
        />
        <Route
          path="/:organization/menu"
          element={
            <OrganizationPage>
              <OrganizationMenu />
            </OrganizationPage>
          }
        />
      </Routes>
    </Router>
  );
};
