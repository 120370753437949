import styled from "styled-components";
import styles from "../../Assets/styles";

export const FilledButton = styled.div`
  cursor: pointer;
  width: ${(props) =>
    props.customStyles ? `${props.customStyles.width}` : "220px"};
  height: ${(props) =>
    props.customStyles ? `${props.customStyles.height}` : "50px"};
  border-radius: ${(props) =>
    props.customStyles ? `${props.customStyles.radius}` : "15px"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: ${styles.NeutralsWhite};
  background-color: ${styles.DarkBlue};
  border: ${`1px dashed ${styles.OffBlack}`};
  div {
    /* background-color: red; */
    width: 40px;
  }
`;

export const UnFilledButton = styled.div`
  cursor: pointer;
  width: ${(props) =>
    props.customStyles ? `${props.customStyles.width}` : "220px"};
  height: ${(props) =>
    props.customStyles ? `${props.customStyles.height}` : "50px"};
  border-radius: ${(props) =>
    props.customStyles ? `${props.customStyles.radius}` : "15px"};
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  gap: 10px;
  font-weight: 700;
  color: ${styles.OffBlack};
  /* background-color: ${styles.DarkBlue}; */
  border: ${`1px solid ${styles.OffBlack}`};
`;

export const RoundButton = styled.div`
  cursor: pointer;
  width: ${(props) =>
    props.customStyles ? `${props.customStyles.width}` : "50px"};
  height: ${(props) =>
    props.customStyles ? `${props.customStyles.height}` : "50px"};

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 10px;
  font-weight: 700;
  color: ${styles.OffBlack};

  border: ${`2px solid ${styles.NeutralsWhite}`};
  border-right-color: transparent;
  transform: ${(props) =>
    props.customStyles && `rotate(${props.customStyles.rotation}deg)`};

  svg {
    transform: ${(props) =>
      props.customStyles && `rotate(-${props.customStyles.rotation}deg)`};
    color: ${styles.NeutralsWhite};
    width: 22px;
    height: 22px;
  }
`;
