import Cookies from "universal-cookie";
import { useMutation, useQuery } from "react-query";
import {
  getSession,
  hasActiveOrPendingSession,
  startSession,
} from "../Api/OrgSessions";
import { useState, useEffect, useMemo } from "react";
import { isLogin } from "../Utils/localStorage";

const cookies = new Cookies();

export const useOrgSession = () => {
  const [session, setSession] = useState({});

  // console.log(session);

  const hasSession = useMemo(() => session?.status === "active", [session]);

  const handleSessionSuccess = (res) => {
    const { data } = res;
    setSession(data ?? {});
    if (data?.status === "pending" || data?.status === "active") {
      console.log("called pending");
      cookies.set("session_token", data.token, { path: "/" });
    } else {
      console.log("called removing");
      cookies.remove("session_token", { path: "/" });
    }
  };

  useEffect(() => {
    const session_token = cookies.get("session_token");
    !!session_token && setSession({ token: session_token, status: "pending" });
  }, []);

  const hasSessionQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false,
    enabled: isLogin() && cookies.get("session_token") === undefined,
    queryKey: ["has_session"],
    queryFn: hasActiveOrPendingSession,
    onSuccess: handleSessionSuccess,
  });

  const getSessionQuery = useQuery({
    refetchOnWindowFocus: true,
    refetchInterval: 20000,
    enabled: session?.token !== undefined && session?.status === "pending",
    queryKey: ["session", session?.token],
    queryFn: () => getSession(session.token),
    onSuccess: handleSessionSuccess,
  });

  const startSessionMutation = useMutation({
    mutationFn: startSession,
    onSuccess: handleSessionSuccess,
    onError: (error) => console.log(error),
  });

  const handleRequestSession = (tableNumber, slug) => {
    const params = {
      organization: slug,
      table_no: tableNumber,
      session_type: "client_created",
    };
    startSessionMutation.mutate(params);
  };

  const loading = {
    getSession: getSessionQuery.isLoading,
    checkingSession: hasSessionQuery.isLoading,
  };

  return {
    loading,
    session,
    hasSession,
    handleRequestSession,
  };
};
