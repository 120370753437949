import BsModal from "react-bootstrap/Modal";
import * as Styles from "./styles";

export const Modals = ({ header, children, curved, blur, ...props }) => {
  return (
    <Styles.Modal
      size="md"
      centered
      {...props}
      curved={curved?.toString()}
      blur={blur?.toString()}
    >
      <BsModal.Header closeButton></BsModal.Header>
      <BsModal.Body>{children}</BsModal.Body>
    </Styles.Modal>
  );
};
export default Modals;
