import { api } from ".";
import { getUser } from "../Utils/localStorage";

export const getCart = async (params) => {
  const { token } = getUser();
  return await api.post(`/api/v1/cart`, params, {
    headers: {
      Authorization: token,
    },
  });
};

export const updateCart = async (params) => {
  const { token } = getUser();
  return await api.post(`/api/v1/cart/edit_item/${params.id}`, params, {
    headers: {
      Authorization: token,
    },
  });
};
