import { useState } from "react";
import { getMenuItemsPreview } from "../Api/MenuApi";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

export const useHome = () => {
  const { organization } = useParams();

  const [menuItemsPreviewIds, setMenuItemsPreviewIds] = useState([]);
  const [menuItemsPreview, setMenuItemsPreview] = useState({
    items: [],
    name: "Menu Items",
  });

  const handlePreviews = () => {
    const params = {
      items_ids: menuItemsPreviewIds,
    };
    return getMenuItemsPreview(organization, params);
  };

  const itemsPreviewIdsQuery = useQuery({
    refetchOnWindowFocus: false,
    enabled: menuItemsPreviewIds.length > 0,
    queryKey: ["previews", menuItemsPreviewIds.length],
    queryFn: handlePreviews,
    onSuccess: (res) => {
      const { data } = res;
      setMenuItemsPreview({ ...menuItemsPreview, items: data });
    },
  });

  const loadingItemsPreview = itemsPreviewIdsQuery.isLoading;

  const loading = { loadingItemsPreview };
  return {
    loading,
    menuItemsPreview,
    setMenuItemsPreview,
    setMenuItemsPreviewIds,
  };
};
