import styled from "styled-components";
import styles from "../../Assets/styles";

export const Holder = styled.div`
  top: 0;
  position: fixed;
  width: 100%;
  height: 70px;
  max-width: 100vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${styles.NeutralsWhite};
  padding: 0px 15px;
  gap: 10px;
  z-index: 999;
  span {
    cursor: pointer;
    font-size: 18px;
  }
  p {
    transition: 0.2s;
    margin: 0;
    cursor: pointer;

    :hover {
      color: ${styles.MidGrey};
    }
  }
`;

export const Sections = styled.div`
  z-index: 999;

  gap: 25px;
  display: flex;
  align-items: center;
  img {
    width: 125px;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const SearchBarHolder = styled.div`
  height: 50px;
  width: 30vw;
  /* min-width: 300px; */
  border-radius: 10px;
  padding: 0px 5px;

  background-color: ${styles.LightGrey};
  @media (max-width: ${1000}px) {
    margin-top: 30px;
    height: 40px;
    min-height: 40px;
    width: 97%;
  }
`;

export const UserImage = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  background-color: ${styles.LightGrey};
  background-image: ${(props) => `url(${props.image})`};
  background-position: center;
  background-size: cover;
  background-color: ${styles.LightGrey};
`;

export const CartButton = styled.div`
  position: relative;
  cursor: pointer;
  svg {
    width: 35px;
    height: 35px;
  }
  @media (max-width: ${1000}px) {
    svg {
      width: 25px;
      height: 25px;
    }
  }
`;

export const Collapser = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  /* align-items: center; */
  position: absolute;
  padding-left: 25px;
  padding-right: 25px;
  width: 100vw;
  background-color: ${styles.NeutralsWhite};
  left: -15px;
  top: 50px;
  gap: 20px;
`;

export const WebCollapser = styled(Collapser)`
  position: absolute;
  top: 60px;
  left: -210px;
  width: 250px;
  border-radius: 10px;
`;

export const UserSection = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  /* border-top: 1px solid ${styles.LightGrey}; */
  gap: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const UserNameHolder = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  p {
    font-size: 15px;
    color: ${styles.OffBlack};
    font-weight: 700;
    margin: 0;
  }
`;
