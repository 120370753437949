import { createContext, useState } from "react";
import { useMutation } from "react-query";
import { getOrder, getOrders, placeOrder } from "../Api/OrderApi";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const OrderContext = createContext();

const OrderStore = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [currentOrder, setCurrentOrder] = useState({});

  const placeOrderMutation = useMutation({
    mutationFn: placeOrder,
    onSuccess: (res) => {
      console.log(res.data);
    },
    onError: (error) => console.log(error),
  });

  const getOrdersMutation = useMutation({
    mutationFn: getOrders,
    onSuccess: (res) => {
      setOrders(res.data.records);
    },
    onError: (error) => console.log(error),
  });

  const getOrderMutation = useMutation({
    mutationFn: getOrder,
    onSuccess: (res) => {
      setCurrentOrder(res.data);
    },
    onError: (error) => console.log(error),
  });

  const handlePlaceOrder = async (slug) => {
    const token = cookies.get("session_token");
    const params = {
      organization: slug,
      token: token,
    };

    await placeOrderMutation.mutateAsync(params);
  };

  const handleShowOrders = (slug) => {
    getOrdersMutation.mutate({
      slug,
    });
  };

  const handleShowOrder = (slug, order) => {
    const { id } = order;
    getOrderMutation.mutate({ slug, id });
  };

  const loading = {
    loadingCart: placeOrderMutation.isLoading,
    orderModal: getOrdersMutation.isLoading || getOrderMutation.isLoading,
  };

  return (
    <OrderContext.Provider
      value={{
        orders,
        loading,
        currentOrder,
        handlePlaceOrder,
        handleShowOrder,
        handleShowOrders,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export default OrderStore;
