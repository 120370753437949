/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getOrgsUnderCategory } from "../Api/CategoryApi";
// import { updateUserAvatar } from "../Api/UserApi";

const useCategory = () => {
  const { category } = useParams();

  const [isLoading, setIsLoading] = useState({});
  const [currentCategory, setCurrentCategory] = useState();
  const [orgsUnderCategory, setOrgsUnderCategory] = useState([]);

  const fetchOrgsUnderCategory = useCallback(async () => {
    setIsLoading({ orgs: true });
    const { data } = await getOrgsUnderCategory(category);
    setOrgsUnderCategory(data);
    setCurrentCategory(category); // remove this it if proves unecessary
    setIsLoading({ orgs: false });
  }, [currentCategory]);

  useEffect(() => {
    fetchOrgsUnderCategory();
  }, [category]);

  return { isLoading, currentCategory, orgsUnderCategory, setCurrentCategory };
};

export default useCategory;
