import styled from "styled-components";
import styles from "../../Assets/styles";

export const Holder = styled.div`
  margin-top: 70px;
  width: 100%;
  min-height: 100vh;
  max-width: 100vw;
  background-color: ${styles.background};
`;

export const HomeHolder = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 100vh;
  padding: 30px 50px;

  @media (max-width: ${1000}px) {
    padding: 20px 0px;
  }
`;

export const LoadHolder = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CategoriesHolder = styled.div`
  padding: 0px 20px;

  gap: 15px;
  width: 100%;
  display: flex;
  overflow-x: scroll;
  /* padding-left: 50px; */
  ::-webkit-scrollbar {
    display: none;
    background-color: red;
  }
`;

export const Category = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  :hover {
    transition: 0.3s;
  }
  p {
    margin: 0;
    color: ${styles.DarkGrey};
    font-size: 13px;
    text-transform: capitalize;
  }
`;

export const CategoryImage = styled.div`
  width: 95px;
  min-width: 95px;
  height: 95px;
  min-height: 95px;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  background-color: ${styles.DarkGrey};
  background-image: ${(props) => `url(${props.image})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
    background-color: red;
  }
  @media (max-width: ${1000}px) {
    width: 90px;
    min-width: 90px;
    height: 90px;
    min-height: 90px;
  }
`;
