import { useState } from "react";
import { useMutation } from "react-query";
import { getCart, updateCart } from "../Api/CartApi";
import { isLogin } from "../Utils/localStorage";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const useCart = () => {
  const { organization } = useParams();
  const [cart, setCart] = useState({});

  const cartMutation = useMutation({
    mutationFn: getCart,
    onSuccess: (res) => {
      const { data } = res;
      setCart(data);
    },
    onError: (error) => console.log(error),
  });

  const updateCartMutation = useMutation({
    mutationFn: updateCart,
    onSuccess: (res) => {
      const { data } = res;
      setCart(data);
    },
    onError: (error) => console.log(error),
  });

  const token = cookies.get("session_token");
  const handleGetCart = () => {
    if (isLogin()) cartMutation.mutate({ organization, token });
  };

  const handleQuantity = (id, quantity) => {
    updateCartMutation.mutate({
      quantity,
      id,
      token,
      organization,
    });
  };

  const loadingCart =
    (cartMutation.isLoading && !cart.id) || updateCartMutation.isLoading;

  const loading = { loadingCart };

  return { cart, loading, setCart, handleGetCart, handleQuantity };
};
