import * as S from "./styles";

export const Input = ({ placeHolder, value, type, onChange, errors }) => {
  return (
    <div>
      <S.Holder>
        <input
          value={value}
          type={type}
          placeholder={placeHolder}
          onChange={(e) => onChange && onChange(e.target.value)}
        />
      </S.Holder>
      <S.Error>{errors}</S.Error>
    </div>
  );
};

export const IconInput = ({
  placeHolder,
  value,
  type,
  onChange,
  errors,
  leftIcon,
}) => {
  return (
    <S.IconInputHolder>
      {leftIcon}
      <input
        value={value}
        type={type}
        placeholder={placeHolder}
        onChange={(e) => onChange && onChange(e.target.value)}
      />
    </S.IconInputHolder>
  );
};
