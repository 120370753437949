import styles from "../../Assets/styles";
import { LoadSpiner } from "../Loader";
import * as S from "./styles";

const variants = {
  signup: {
    height: "40px",
    width: "250px",
    radius: "7px",
  },
  leftRound: {
    height: "50px",
    width: "50px",
    rotation: 45,
  },
  rightRound: {
    height: "50px",
    width: "50px",
    rotation: 220,
  },
  login: {
    height: "40px",
    width: "100%",
    bgColor: styles.OffBlack,
    radius: "10px",
  },
};

export const FilledButton = ({ text, onClick, variant, isLoading }) => {
  return (
    <S.FilledButton
      customStyles={variants[variant]}
      onClick={() => onClick && onClick()}
    >
      {isLoading ? <LoadSpiner /> : text}
    </S.FilledButton>
  );
};

export const UnFilledButton = ({ text, onClick, variant, leftIcon }) => {
  return (
    <S.UnFilledButton
      customStyles={variants[variant]}
      onClick={() => onClick && onClick()}
    >
      {leftIcon}
      {text}
    </S.UnFilledButton>
  );
};

export const RoundButton = ({ onClick, variant, icon }) => {
  return (
    <S.RoundButton
      customStyles={variants[variant]}
      onClick={() => onClick && onClick()}
    >
      {icon}
    </S.RoundButton>
  );
};
