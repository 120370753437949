import {
  BigOval,
  BigOvalTable,
  FourSitSquare,
  FourSquareOct,
  PentFiveSit,
  TensitTable,
  ThreesitTable,
  TwoSquareTable,
} from "../../Assets/icons/icons";

export const allMovebles = [
  {
    name: "ThreeSitTable",
    icon: <ThreesitTable />,
    displayName: "Three sit round table",
    dimensions: { height: 50, width: 50 },
  },
  {
    name: "TenSitTable",
    icon: <TensitTable />,
    displayName: "Ten sit round table",
    dimensions: { height: 50, width: 50 },
  },
  {
    name: "TwoSquareTable",
    icon: <TwoSquareTable />,
    displayName: "Two sit square table",
    dimensions: { height: 40, width: 70 },
  },
  {
    name: "FourSitSquare",
    icon: <FourSitSquare />,
    displayName: "Four sit rect table",
    dimensions: { height: 60, width: 50 },
  },
  {
    name: "FourSquareOct",
    icon: <FourSquareOct />,
    displayName: "Four sit side square table",
    dimensions: { height: 55, width: 50 },
  },
  {
    name: "PentFiveSit",
    icon: <PentFiveSit />,
    displayName: "Six sit pent table",
    dimensions: { height: 50, width: 50 },
  },
  {
    name: "BigOvalTable",
    icon: <BigOvalTable />,
    displayName: "Six sit oval table",
    dimensions: { height: 45, width: 60 },
  },
  {
    name: "BigOval",
    icon: <BigOval />,
    displayName: "Eight sit oval table",
    dimensions: { height: 55, width: 80 },
  },
];
