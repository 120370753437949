import { QueryClient, QueryClientProvider } from "react-query";
import { UniversalModals } from "./Components/UniversalModals";
import BuilderStore from "./Context/builderCtx";
import ControllerStore from "./Context/controllerCtx";
import UserStore from "./Context/userContext";
import { Pages } from "./Routes";
import OrderStore from "./Context/orderContext";

const queryClient = new QueryClient();

export const App = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <UserStore>
          <BuilderStore>
            <ControllerStore>
              <OrderStore>
                <Pages />
                <UniversalModals />
              </OrderStore>
            </ControllerStore>
          </BuilderStore>
        </UserStore>
      </QueryClientProvider>
    </>
  );
};

export default App;
