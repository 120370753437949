import {
  barcategory,
  buffetcategory,
  burgercategory,
  categoryimage,
  categoryimage2,
  categoryimage3,
  icecreamcategory,
  pizzacategory,
  steakcategory,
} from "../Assets/images/images";

const images = [
  { id: 6, src: burgercategory },
  { id: 5, src: barcategory },
  { id: 2, src: pizzacategory },
  { id: 4, src: steakcategory },
  { id: 3, src: icecreamcategory },
  { id: 1, src: buffetcategory },
  { id: 7, src: categoryimage },
  { id: 8, src: categoryimage2 },
  { id: 9, src: categoryimage3 },
];

export const getImage = (id) => {
  return images.find((img) => img.id === id);
};
