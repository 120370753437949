import * as S from "./styles";

export const OrganizationCard = ({ org, onClick }) => {
  return (
    <>
      <S.Organizations onClick={() => onClick && onClick()}>
        <img src={org?.logo?.url} alt={"logo"} />
        <div className="details-holder">
          <p className="org-name">{org.name}</p>
          <p className="org-location">{org.address}</p>
        </div>
      </S.Organizations>
    </>
  );
};
