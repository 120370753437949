import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useCart } from "./useCart";
import { useOrgSession } from "./useOrgSession";
import useOrder from "./useOrder";

export const useQuickAccesser = (navigate, branding) => {
  const [searchParams] = useSearchParams();
  const { session, hasSession, handleRequestSession } = useOrgSession();
  const cartParams = useCart({ token: session?.token });
  const orderParams = useOrder();

  const [selected, setSelected] = useState({ cart: false });
  const [tableNumber, setTableNumber] = useState("");

  const { setCart, handleGetCart, handleQuantity } = cartParams;
  const { slug } = branding;
  const {
    orders,
    currentOrder,
    handlePlaceOrder,
    handleShowOrders,
    handleShowOrder,
  } = orderParams;

  const handleNavigate = (slug) => {
    navigate(slug);
  };

  const handleViewMenu = () => {
    navigate(`/${slug}/menu`);
  };

  const hideAccesser = useMemo(() => searchParams.get("item"), [searchParams]);

  const routeTableNumber = useMemo(
    () => searchParams.get("table"),
    [searchParams]
  );

  useEffect(() => {
    if (routeTableNumber) {
      setTableNumber(routeTableNumber);
      setSelected({ table: true });
    }
  }, [routeTableNumber]);

  const placeOrder = async () => {
    await handlePlaceOrder(slug);
    console.log("caleeddddd");
    setCart({});
  };

  const showOrders = () => {
    handleShowOrders(slug);
  };

  const showOrder = (order) => {
    handleShowOrder(slug, order);
  };

  const loading = { ...cartParams.loading, ...orderParams.loading };
  const actions = {
    navigate: (slug) => handleNavigate(slug),
    handleShowCart: handleGetCart,
    handlePlaceOrder: placeOrder,
    handleShowOrders: showOrders,
    handleShowOrder: showOrder,
    handleQuantity,
    handleViewMenu,
    handleTableNumberChange: (num) => {
      setTableNumber(num);
    },
    handleQrScan: (data) => {
      console.log("from here", data);
      window.open(data);
    },
    handleRequestSession: () => handleRequestSession(tableNumber, slug),
  };

  return {
    orders,
    actions,
    session,
    selected,
    hasSession,
    tableNumber,
    hideAccesser,
    currentOrder,
    ...cartParams,
    loading,
    setSelected,
    handleNavigate,
    handleViewMenu,
  };
};
