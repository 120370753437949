/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  addToCart,
  getMenuCategories,
  getMenuItems,
  searchMenuItems,
} from "../Api/MenuApi";
import { useParams, useSearchParams } from "react-router-dom";
import { useContext } from "react";
import { controllerContext } from "../Context/controllerCtx";
import { isLogin } from "../Utils/localStorage";
import useDebounce from "./useDebounce";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const useMenu = (navigate) => {
  const { organization } = useParams();
  const [searchParams] = useSearchParams();

  const [menuCategories, setMenuCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    id: 0,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [startSearching, setStartSearching] = useState(false);
  const [searchedMenuItems, setSearchedMenuItems] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const [menuItems, setMenuItems] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState({});
  const [showModals, setShowModals] = useState({ menuItem: false });

  const [controllerState, setControllerState] = useContext(controllerContext);

  const category_id = useMemo(
    () => searchParams.get("category"),
    [searchParams]
  );

  const item_id = useMemo(() => searchParams.get("item"), [searchParams]);

  useEffect(() => {
    getItemsOnLoad();
  }, [category_id, menuCategories]);

  useEffect(() => {
    getItemOnLoad();
  }, [item_id, menuItems]);

  const getItemsOnLoad = useCallback(async () => {
    const defaultCategoryRoute = `?category=${menuCategories[0]?.id}`;
    if (category_id && menuCategories.length) {
      const category = menuCategories.find(
        (cat) => cat.id === parseInt(category_id)
      );
      if (category) {
        setSelectedCategory(category);
        menuMutation.mutate(category_id);
      } else {
        navigate(defaultCategoryRoute);
      }
    } else if (menuCategories.length) {
      navigate(defaultCategoryRoute);
    }
  }, [category_id, menuCategories]);

  const getItemOnLoad = useCallback(async () => {
    if (menuItems.length && item_id) {
      const item = menuItems.find((item) => item.id === parseInt(item_id));
      if (item) {
        setSelectedMenuItem(item);
        setShowModals({ ...showModals, menuItem: true });
      } else if (searchMenuItems.length) {
        const item = searchedMenuItems.find(
          (item) => item.id === parseInt(item_id)
        );
        // maybe navigate to category ?
        setSelectedMenuItem(item);
        setShowModals({ ...showModals, menuItem: true });
      }
    }
  }, [item_id, menuItems]);

  const categoriesQuery = useQuery({
    enabled: true,
    queryKey: ["category"],
    queryFn: () => getMenuCategories(organization),
    onSuccess: (res) => onCategoriesSuccess(res),
  });

  const onCategoriesSuccess = (res) => {
    const { data } = res;
    setMenuCategories(data);
  };

  const menuMutation = useMutation({
    mutationFn: getMenuItems,
    onSuccess: (res) => {
      const { data } = res;
      setMenuItems(data);
    },
    onError: (error) => console.log(error),
  });

  const addToCartMutation = useMutation({
    mutationFn: addToCart,
    onSuccess: (res) => {
      setShowModals({});
      navigate(`?category=${category_id}`);
    },
    onError: (error) => console.log(error),
  });

  const handleCategoryChange = (cat) => {
    navigate(`?category=${cat.id}`);
  };

  const handleMenuItemChange = (item) => {
    navigate(`?category=${category_id}&item=${item.id}`);
  };

  const handleMenuItemClose = () => {
    setShowModals({ ...showModals, menuItem: false });
    navigate(`?category=${category_id}`);
  };

  const handleShowSearch = () => {
    setSearchTerm("");
    setShowModals({ searchMenu: true });
  };

  const hideSearchMenuItem = () => {
    setSearchTerm("");
    setStartSearching(false);
    setShowModals({ searchMenu: false });
  };

  const handleSearchMenuItems = (e) => {
    setStartSearching(true);
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    const query = {
      name: debouncedSearchTerm,
      organization,
    };
    return searchMenuItems({ query });
  };

  const searchItems = useQuery({
    enabled: startSearching,
    queryKey: ["search", debouncedSearchTerm, startSearching],
    queryFn: handleSearch,
    onSuccess: (res) => {
      const { data } = res;
      setSearchedMenuItems(data);
    },
  });

  const handleAddToCart = (values) => {
    if (isLogin()) {
      const { price, menu_item_id, quantity, addOns } = values;
      const token = cookies.get("session_token");

      const params = {
        token,
        price,
        quantity,
        menu_item_id,
        organization,
        add_ons: JSON.stringify(addOns),
      };
      addToCartMutation.mutate(params);
    } else {
      setControllerState({
        ...controllerState,
        modals: { login: true, signup: false, canCancel: true },
      });
    }
  };

  const loadingCategories = categoriesQuery.isLoading;
  const loadingMenuItems = menuMutation.isLoading;
  const loadingAddToCart = addToCartMutation.isLoading;
  const loadingSearch = searchItems.isLoading;
  const loading = {
    loadingCategories,
    loadingMenuItems,
    loadingAddToCart,
    loadingSearch,
  };

  return {
    loading,
    menuItems,
    showModals,
    menuCategories,
    selectedCategory,
    selectedMenuItem,
    searchedMenuItems,
    setShowModals,
    handleAddToCart,
    setSelectedMenuItem,
    setSelectedCategory,
    handleCategoryChange,
    handleMenuItemChange,
    handleMenuItemClose,
    handleShowSearch,
    hideSearchMenuItem,
    handleSearchMenuItems,
  };
};
