import { RoundButton } from "../Buttons";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import * as S from "./styles";
import { useState } from "react";

export const Slider = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleNav = (param) => {
    setCurrentIndex(currentIndex + param);
  };
  return (
    <S.Holder image={data[currentIndex].image}>
      <S.NavsHolder>
        <RoundButton
          onClick={() => currentIndex !== 0 && handleNav(-1)}
          variant={"leftRound"}
          icon={<IoArrowBackOutline />}
        />
        <RoundButton
          onClick={() => currentIndex + 1 < data?.length && handleNav(1)}
          variant={"rightRound"}
          icon={<IoArrowForwardOutline />}
        />
      </S.NavsHolder>
    </S.Holder>
  );
};
