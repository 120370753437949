import { useNavigate } from "react-router-dom";
import { userLogout } from "../../Api/UserApi";
import * as S from "./styles";
import { getUser, isLogin } from "../../Utils/localStorage";

export const AcountRoutes = ({ avatar }) => {
  const navigate = useNavigate();

  const handleLogOut = () => {
    userLogout();
    navigate("/log-in");
  };

  const handleLogin = () => {
    navigate("/log-in");
  };

  const handleSignUp = () => {
    navigate("/sign-up");
  };

  return (
    <>
      {isLogin() ? (
        <>
          <S.UserNameHolder>
            <S.UserImage
              image={`${
                avatar?.new ? URL.createObjectURL(avatar?.new) : avatar?.current
              }`}
            ></S.UserImage>
            <p>{getUser().name}</p>
          </S.UserNameHolder>

          <p>Profile</p>

          <p onClick={() => handleLogOut()}>Log out</p>
        </>
      ) : (
        <>
          <S.UserNameHolder>
            <S.UserImage></S.UserImage>
            <p>Not signed in </p>
          </S.UserNameHolder>

          <p onClick={() => handleLogin()}>Log into your account</p>
          <p onClick={() => handleSignUp()}>Sign Up to Waithere</p>
        </>
      )}
    </>
  );
};

export const MenuRoutes = ({}) => {
  const navigate = useNavigate();

  return (
    <>
      <p onClick={() => navigate("/home")}>Home</p>

      <p>Restaurants</p>
      <p>Reservations</p>
      <p>Favorites</p>
      <p>Recomended</p>
    </>
  );
};
