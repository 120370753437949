import { createContext, useState } from "react";

export const controllerContext = createContext();

const initialState = { isAdding: false };

const ControllerStore = ({ children }) => {
  const [controllerState, setControllerState] = useState(initialState);

  return (
    <controllerContext.Provider value={[controllerState, setControllerState]}>
      {children}
    </controllerContext.Provider>
  );
};

export default ControllerStore;
