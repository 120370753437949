import * as S from "./styles";
import { TbTemplate } from "react-icons/tb";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { useContext, useState } from "react";
import { Templates } from "./Templates";
import { Items } from "./Items";
import { FiUploadCloud } from "react-icons/fi";
import { saveBuilderState } from "../../Api/BuilderApi";
import { builderContext } from "../../Context/builderCtx";

export const ToolBar = () => {
  const [expanded, setExpanded] = useState(false);
  const [focused, setFocused] = useState(false);

  const [builderState] = useContext(builderContext);

  const handleNavClick = (clicked) => {
    setExpanded(focused === clicked && expanded ? false : true);
    setFocused(focused === clicked ? false : clicked);
  };

  return (
    <>
      <S.Container expanded={expanded}>
        <S.Holder expanded={expanded}>
          <S.NavOption
            focused={focused === "Templates"}
            onClick={() => handleNavClick("Templates")}
          >
            <TbTemplate />
            <span>Templates</span>
          </S.NavOption>

          <S.NavOption
            focused={focused === "Items"}
            onClick={() => handleNavClick("Items")}
          >
            <AiOutlineAppstoreAdd />
            <span>Items</span>
          </S.NavOption>

          <S.NavOption
            focused={focused === "save"}
            onClick={() =>
              saveBuilderState(builderState.builderId, builderState)
            }
          >
            <FiUploadCloud />
            <span>Save</span>
          </S.NavOption>
        </S.Holder>
        <S.ExpandedHolder>
          {focused === "Templates" && <Templates />}
          {focused === "Items" && <Items />}
        </S.ExpandedHolder>
      </S.Container>
    </>
  );
};

// node incoming maybe rails hehe
// node incoming maybe rails hehe
// node incoming maybe rails hehe
// node incoming
