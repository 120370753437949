/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { getBranding } from "../Api/BrandingApi";
import { useParams } from "react-router-dom";

const useBranding = (fetchOnLoad) => {
  const { organization } = useParams();

  const [branding, setBranding] = useState();
  const [loading, setLoading] = useState({ branding: true });

  const fetchBranding = useCallback(async () => {
    try {
      setLoading({ branding: true });
      const { data } = await getBranding(organization);
      setBranding(data);
      setLoading({ branding: false });
    } catch (e) {
      console.log("error : ", e);
    }
  }, [organization]);

  useEffect(() => {
    fetchOnLoad && fetchBranding();
  }, [organization]);

  return {
    loading,
    branding,
    fetchBranding,
  };
};

export default useBranding;
