/* eslint-disable react-hooks/exhaustive-deps */
import * as S from "./styles";
import { Home } from "waitherepackages";
import "waitherepackages/dist/index.css";
import useIsMobile from "../../../Hooks/isMobile";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useHome } from "../../../Hooks/useHome";

export const OrgHome = ({ branding = {} }) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const {
    loading,
    menuItemsPreview,
    setMenuItemsPreview,
    setMenuItemsPreviewIds,
  } = useHome();

  const { menuBdrColor, menuItemColor, menuTxtColor } = branding?.menu;
  const currentBranding = {
    ...(branding?.homepage || {}),
    menuBdrColor,
    menuItemColor,
    menuTxtColor,
  };

  useEffect(() => {
    setMenuItemsPreview({
      name: currentBranding?.itemsPreviewName || "Items",
      items: [],
    });
    setMenuItemsPreviewIds(currentBranding.menuItemsPreviewIds || []);
  }, [currentBranding?.menuItemsPreviewIds]);

  const actions = {
    handleCardClick: (item) => navigate(item.slug),
  };

  return (
    <>
      <S.Holder>
        {branding?.id && (
          <Home
            actions={actions}
            loading={loading}
            isDefaultMoblie={isMobile}
            currentValues={currentBranding}
            menuItems={menuItemsPreview}
          />
        )}
      </S.Holder>
    </>
  );
};
