import styled from "styled-components";
import styles from "../../Assets/styles";

export const Holder = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${styles.NeutralsWhite};
  border: ${`1px dashed ${styles.OffBlack}`};
`;

export const ProgresHolder = styled.div`
  position: relative;
  padding-left: 20px;
  width: 100%;
  height: 90px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-top: 10px;
  background-color: ${styles.NeutralsWhite};
  border: ${`1px solid ${styles.OffBlack}`};
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  svg {
    height: 25px;
    width: 25px;
    color: ${styles.DarkBlue};
  }
`;

export const TopFlex = styled.div`
  display: flex;
  width: 100%;
  border-radius: 10px;
`;

export const Uploader = styled.div`
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

export const Text = styled.p`
  margin-top: 10px;
  font-size: 16px;
  font-weight: 700;
  color: ${styles.OffBlack};
  span {
    text-decoration: underline;
  }
`;

export const SubText = styled.p`
  font-size: 10px;
  margin-top: -5px;
  color: ${styles.OffBlack};
`;

export const UploadIcon = styled.div`
  /* background-color: red; */
  position: relative;
  height: 50px;
  svg {
    width: 50px;
    height: 50px;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: ${styles.OffBlack};
    border-radius: 50%;
    width: 25px;
    height: 25px;

    svg {
      width: 15px;
      height: 15px;
      color: ${styles.NeutralsWhite};
    }
  }
`;

export const FloorPreview = styled.div`
  transition: 0.2s;
  width: 70px;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${(props) => !props.image && styles.FocusedGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${(props) => `url(${props.image})`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: ${(props) => props.focused && `1px solid ${styles.IconGrey}`};
  svg {
    color: ${styles.IconGrey};
    width: 25px;
    height: 25px;
  }
`;

export const LoaderHolder = styled.div`
  border-radius: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    font-weight: 700;
    color: ${styles.OffBlack};
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
`;

export const FileName = styled.p`
  font-weight: 700;
  color: ${styles.OffBlack};
  margin: 0;
  padding: 0;
  margin-left: 10px;
`;

export const FileSize = styled.p`
  font-weight: 600;
  color: ${styles.OffBlack};
  margin: 0;
  padding: 0;
  margin-left: 10px;
`;

export const Loader = styled.div`
  border-radius: 100px;
  width: 86%;
  min-height: 10px;
  max-height: 10px;
  display: flex;
  cursor: pointer;
  background-color: ${styles.IconGrey};
  div {
    transition: 0.2s;
    width: ${(props) => `${props.progress}%`};
    background-color: ${styles.DarkBlue};
    border-radius: 100px;
    min-height: 10px;
    max-height: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
