import { api } from ".";
import { getUser } from "../Utils/localStorage";

export const getBranding = async (organization) => {
  const user = getUser();

  return await api.get(`/api/v1/public/branding/${organization}`, {
    headers: {
      Authorization: user?.token,
    },
  });
};
