/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from "react";
import { verifyLogIn } from "../Utils/localStorage";

export const userContext = createContext();

const initialState = { org: {}, user: {} };

const UserStore = ({ children }) => {
  useEffect(() => {
    verifyLogIn();
  }, []);

  const [userState, setUserState] = useState(initialState);

  return (
    <userContext.Provider value={[userState, setUserState]}>
      {children}
    </userContext.Provider>
  );
};

export default UserStore;
